<template>
  <svg
    :class="cn('inline-block align-middle focus:outline-none', props.class)"
    :role="role">
    <use :xlink:href="`#icon-${name}`" />
  </svg>
</template>

<script setup lang="ts">
import { cn } from "@/js/utils/cn";
import { type IconName } from "@/types/icons";
import type { HTMLAttributes } from "vue";

type Props = {
  name: IconName;
  role?: string;
  class?: HTMLAttributes["class"];
};

const props = withDefaults(defineProps<Props>(), {
  role: "presentation",
});
</script>
